import type React from 'react';
import { useEffect, useRef } from 'react';

import { LucideCircleX } from 'lucide-react';

const Modal = ({
  title = '',
  show,
  children,
  onHide,
  modalClass,
  showXIcon = true,
  disableClickOutside = false,
}: {
  title?: string;
  show: boolean;
  children?: React.ReactNode | undefined;
  onHide?: () => void;
  modalClass?: string;
  showXIcon?: boolean;
  disableClickOutside?: boolean;
}): React.ReactElement => {
  const trigger = useRef(null);
  const modal = useRef(null);

  // close on click outside
  useEffect(() => {
    if (disableClickOutside) return;
    const clickHandler = ({ target }): void => {
      if (!modal.current) return;
      if (!show || modal.current.contains(target) || trigger?.current?.contains(target)) {
        return;
      }
      if (onHide) {
        onHide();
      }
    };
    document.addEventListener('click', clickHandler);
    return () => {
      document.removeEventListener('click', clickHandler);
    };
  });

  // close if the esc key is pressed
  useEffect(() => {
    if (disableClickOutside) return;
    const keyHandler = ({ keyCode }): void => {
      if (!show || keyCode !== 27) return;
      if (onHide) {
        onHide();
      }
    };
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  });

  return (
    <>
      <div>
        <div
          className={`fixed flex min-h-full w-full items-center justify-center bg-black/25 px-4 py-5 inset-0 overflow-y-auto ${
            show ? 'block' : 'hidden'
          }`}
          style={{ zIndex: '99' }}
        >
          <div ref={modal} className={`rounded-lg p-6 ${modalClass || 'bg-white w-full max-w-[400px]'}`}>
            <div className={`flex justify-between ${title ? 'mb-5' : ''}`}>
              <h3 className="pb-1 font-semibold text-dark sm:text-2xl">{title}</h3>
              {showXIcon ? (
                <LucideCircleX
                  className="h-6 w-6 text-slate-500 hover:text-indigo-700 cursor-pointer"
                  onClick={() => {
                    if (onHide) {
                      onHide();
                    }
                  }}
                />
              ) : null}
            </div>
            {children || 'Nothing to show here.'}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
